export enum MAINNET {
	'base' = 'Base',
	'avalanche' = 'Avalanche',
	'local' = 'Base',
	'bnb' = 'Binance-Smart-Chain',
}

export enum TESTNET {
	'movement_parthenon' = 'Move-EVM',
}
