import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { SmallItemCard } from 'pages/NewDashboard/components/SmallItemCard';
import { Box, Button, Typography } from '@mui/material';
import { AmountInputBox } from 'pages/Landing/components/AmountInputBox';
import CustomDropdown from 'pages/Landing/components/CustomDropdown';
import { vaultActions } from 'store/vaults';
import styled from '@emotion/styled';
import { useNetwork } from 'hooks/useNetwork';
import { getErc20Contract } from 'utils/contractHelpers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { getDepositContract } from 'utils/depositContractHelpers';
import { toast } from 'react-toastify';
import { getUserDeposit } from 'apis';
import { hasOneHourPassed } from 'utils/lockDuration';
import moment from 'moment/moment';
import { useEthersSigner } from 'hooks/useEtherSigner';
import { useAccount } from 'wagmi';
import { appActions } from 'store/app';
import { getErrorMessage } from 'data/errors';

const Row = styled('div')`
	display: grid;
	grid-template-columns: repeat(8, 1fr);
`;

const FlexRow = styled('div')`
	display: flex;
	flex-direction: row;
`;

const Text = styled(Typography)`
	font-weight: 500;

	:hover {
		color: #235ee1;
		cursor: pointer;
	}
`;

const Column = styled('div')`
	display: flex;
	flex-direction: column;
`;

const SpaceBetween = styled(Column)`
	height: 100%;
	justify-content: space-between;
`;

const StyledButton = styled(Button)`
	display: flex;
	padding: 12px 32px;
	box-shadow: 0 4px 4px transparent;
	border-radius: 8px;
	overflow: hidden;

	:hover {
		box-shadow: 0 4px 4px transparent;
	}
`;

export const WithdrawPanel = () => {
	const [lockedTime, setLockedTime] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [balance, setBalance] = useState('0');
	const [contractBal, setContractBal] = useState('0');
	const [amount, setAmount] = useState('');
	const { address: account } = useAccount();
	const dispatch = useDispatch();
	const appVault = useSelector((state: RootState) => state.vault);
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedAsset = selectedVault.token.find((d) => d.name === 'USDC');
	const selectedContract = selectedVault.exchanger.find(
		(d) => d.name === appVault.exchange,
	);
	const signer: any = useEthersSigner();
	useNetwork();

	const getUserData = useCallback(async () => {
		try {
			const res = await getUserDeposit({
				contract: selectedContract?.contractAddress || '',
				address: account || '',
			});
			if (res.lastDeposit.length > 0) {
				setLockedTime(res.lastDeposit[0].createdAt);
			}
		} catch (e) {
			//
		}
	}, [account, selectedContract?.contractAddress]);

	const buttonLabel = useMemo(() => {
		if (!account) return 'Connect Wallet';

		if (loading) return 'Please wait...';

		if (lockedTime && hasOneHourPassed(lockedTime || '')) {
			return (
				hasOneHourPassed(lockedTime || '') &&
				`Unlock at ${moment(lockedTime)
					.add(1, 'hours')
					.format('DD-MMM, hh:mm A')}`
			);
		}

		return 'Withdraw';
	}, [account, loading, lockedTime]);

	const fetchBalance = useCallback(async () => {
		try {
			if (account) {
				const assetContract = getErc20Contract(
					selectedAsset?.address || '',
					signer?.provider.getSigner(account).connectUnchecked(),
				);
				const assetBalance = await assetContract.balanceOf(account);
				setBalance(
					formatUnits(assetBalance, selectedAsset?.decimalValue),
				);
			} else {
				setBalance('0');
			}
		} catch (e) {
			//
		}
	}, [account, signer, selectedAsset?.address, selectedAsset?.decimalValue]);

	const fetchContractBalance = useCallback(async () => {
		try {
			if (account) {
				dispatch(
					appActions.setLoading({
						loading: true,
						message: 'Please Wait...',
					}),
				);
				const contract = getDepositContract(
					selectedContract?.contractAddress || '',
					signer?.provider.getSigner(account).connectUnchecked(),
				);

				// fetch balance
				const contractBalance = await contract.balanceOf(account);
				const bal = await contract.convertToAssets(contractBalance);
				setContractBal(
					formatUnits(bal, selectedAsset?.decimalValue) || '0',
				);
			} else {
				setContractBal('0');
			}
		} catch (e) {
			//
		} finally {
			dispatch(appActions.setLoading({ loading: false, message: '' }));
		}
	}, [
		account,
		selectedContract?.contractAddress,
		signer?.provider,
		selectedAsset?.decimalValue,
		dispatch,
	]);

	const withdraw = async () => {
		try {
			setLoading(true);

			const res = await getUserDeposit({
				contract: selectedContract?.contractAddress || '',
				address: account || '',
			});
			if (res.lastDeposit.length > 0) {
				setLockedTime(res.lastDeposit[0].createdAt);
				if (!hasOneHourPassed(res.lastDeposit[0].createdAt)) {
					const contract = getDepositContract(
						selectedContract?.contractAddress || '',
						signer?.provider.getSigner(account).connectUnchecked(),
					);

					const tx = await contract.withdraw(
						parseUnits(amount, selectedAsset?.decimalValue),
						account,
						account,
					);
					await tx.wait();
					toast('Successfully withdrawn from vault contract', {
						type: 'success',
					});
					setAmount('0');

					return;
				}
			}
			toast('Sorry your amount is locked for 1h', {
				type: 'error',
			});
		} catch (e: any) {
			toast(
				getErrorMessage(e?.error?.code || e?.code, e) ||
					'Something went wrong',
				{
					type: 'error',
				},
			);
		} finally {
			fetchContractBalance();
			fetchBalance();
			setLoading(false);
		}
	};

	const onPercentClick = (txt: number) => {
		setAmount(((parseFloat(contractBal) * txt) / 100).toString());
	};

	useEffect(() => {
		fetchContractBalance();
		getUserData();
		fetchBalance();
	}, [fetchBalance, fetchContractBalance, getUserData]);

	return (
		<SpaceBetween>
			<Column>
				<SmallItemCard />
				<Box height={16} />
				<AmountInputBox
					text={amount}
					onChange={setAmount}
					leftTxt={
						'$' +
						parseFloat(
							parseFloat(balance).toFixed(2),
						).toLocaleString()
					}
					rightTxt={
						'$' +
						parseFloat(
							parseFloat(contractBal).toFixed(2),
						).toLocaleString()
					}
				/>
				<Box height={10} />
				<Row>
					<Text onClick={() => onPercentClick(25)}>25%</Text>
					<Text onClick={() => onPercentClick(50)}>50%</Text>
					<Text onClick={() => onPercentClick(100)}>Max</Text>
				</Row>
				<Box height={16} />
				<CustomDropdown
					dataSource={selectedVault.exchanger.map((d) => {
						return { name: d.name, image: d.image, value: d.value };
					})}
					value={selectedVault.exchange}
					onChange={(d) =>
						dispatch(vaultActions.changeExchange(d.target.value))
					}
					image={
						selectedVault.exchanger.find(
							(d) =>
								d.value.toLowerCase() ===
								selectedVault.exchange.toLowerCase(),
						)?.image || ''
					}
				/>
				<Box height={16} />
				{selectedVault.network && (
					<CustomDropdown
						dataSource={
							selectedVault.exchanger.find(
								(d) =>
									d.value.toLowerCase() ===
									selectedVault.exchange.toLowerCase(),
							)?.network || []
						}
						value={selectedVault.network}
						onChange={(d) =>
							dispatch(vaultActions.changeNetwork(d.target.value))
						}
						image={
							selectedVault.exchanger.find(
								(d) => d.value === selectedVault.exchange,
							)?.image || ''
						}
					/>
				)}
				<hr />
				<Box height={5} />
				<FlexRow>
					<Typography color={'#666677'}>Withdraw from: </Typography>
					<Box width={5} />
					<Typography> {selectedVault.name}</Typography>
				</FlexRow>
				<Box height={5} />
				<Box height={5} />
			</Column>
			<Column>
				<StyledButton
					variant={'contained'}
					onClick={() => withdraw()}
					disabled={loading || parseFloat(amount || '0') === 0}
				>
					<Typography fontWeight={600} color={'#fff'}>
						{buttonLabel}
					</Typography>
				</StyledButton>
				<Box height={32} />
			</Column>
		</SpaceBetween>
	);
};
