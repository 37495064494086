import React, { useCallback, useEffect, useState } from 'react';
import { NewDashboard } from 'pages/NewDashboard';
import { DepositCard } from 'pages/Landing/DepositCard';
import { useNetwork } from 'hooks/useNetwork';
import { getDepositContract } from 'utils/depositContractHelpers';
import { formatUnits } from 'ethers/lib/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useAccount } from 'wagmi';
import { useEthersSigner } from 'hooks/useEtherSigner';
import { appActions } from 'store/app';

export const Landing = () => {
	const [contractBal, setContractBal] = useState('0');
	const appVault = useSelector((state: RootState) => state.vault);
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedAsset = selectedVault.token.find((d) => d.name === 'USDC');
	const selectedContract = selectedVault.exchanger.find(
		(d) => d.name === appVault.exchange,
	);
	const { address: account } = useAccount();
	const signer: any = useEthersSigner();
	const dispatch = useDispatch();
	useNetwork();

	const fetchContractBalance = useCallback(async () => {
		try {
			if (account) {
				const contract = getDepositContract(
					selectedContract?.contractAddress || '',
					signer?.provider.getSigner(account).connectUnchecked(),
				);

				// fetch balance
				const contractBalance = await contract.balanceOf(account);
				const bal = await contract.convertToAssets(contractBalance);
				setContractBal(
					formatUnits(bal, selectedAsset?.decimalValue) || '0',
				);
			} else {
				setContractBal('0');
			}
		} catch (e) {
			//
		} finally {
			dispatch(appActions.setLoading({ loading: false, message: '' }));
		}
	}, [
		account,
		selectedContract?.contractAddress,
		signer?.provider,
		selectedAsset?.decimalValue,
		dispatch,
	]);

	useEffect(() => {
		fetchContractBalance().then(console.log).catch(console.log);
	}, [fetchContractBalance]);

	return (
		<>
			{account && parseFloat(contractBal) > 0 ? (
				<NewDashboard />
			) : (
				<DepositCard
					onSuccess={() => {
						fetchContractBalance();
					}}
				/>
			)}
		</>
	);
};
