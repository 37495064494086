// api.ts
import axios, { AxiosResponse } from 'axios';

interface DepositRequest {
	contract: string;
	address: string;
	token?: string;
	amount: string;
}

interface DepositResponse {
	success: boolean;
	message: string;
	// Add more fields based on your API response structure
}

const apiClient = axios.create({
	baseURL: 'https://apis.vcred.trade', // Replace with your actual API base URL
	headers: {
		'Content-Type': 'application/json',
	},
});

interface UserDepositParams {
	contract: string;
	address: string;
	token?: string;
	amount?: string;
}

interface UserDepositResponse {
	address: string;
	amount: string;
	contract: string;
	createdAt: string;
	id: string;
	updatedAt: string;
}

export const userDeposit = async (
	depositData: DepositRequest,
): Promise<DepositResponse> => {
	try {
		const response: AxiosResponse<DepositResponse> = await apiClient.post(
			'/api/user/last-deposit',
			depositData,
		);
		return response.data;
	} catch (error: any) {
		throw new Error(
			error.response?.data?.message ||
				'An error occurred while processing your request.',
		);
	}
};

export const getUserDeposit = async (
	params: UserDepositParams,
): Promise<any> => {
	try {
		const response = await apiClient.get<{
			lastDeposit: UserDepositResponse[];
		}>('/api/user/last-deposit', { params });
		return response.data;
	} catch (error: any) {
		throw new Error(
			error.response?.data?.message || 'Error fetching user deposit data',
		);
	}
};

export const getTotalWallet = async (): Promise<any> => {
	try {
		const response = await apiClient.get<{
			lastDeposit: UserDepositResponse[];
		}>('/api/stats/users-summary');
		return response.data;
	} catch (error: any) {
		throw new Error(
			error.response?.data?.message || 'Error fetching user deposit data',
		);
	}
};

/*export const getStat = async () => {
	try {
		const response: AxiosResponse<any> = await apiClient.get('/api/stats');
		return response.data;
	} catch (error: any) {
		throw new Error(
			error.response?.data?.message || 'Error fetching user deposit data',
		);
	}
};*/
