import { notify } from 'utils/notify';

enum ErrorCodes {
	ParseError = -32700,
	InvalidRequest = -32600,
	MethodNotFound = -32601,
	InvalidParams = -32602,
	InternalError = -32603,
	ServerError = -32000, // Range: -32000 to -32099 list soon
	InvalidInput = -32000,
	ResourceNotFound = -32001,
	ResourceUnavailable = -32002,
	TransactionRejected = -32003,
	MethodNotSupported = -32004,
	LimitExceeded = -32005,
	JsonRpcVersionNotSupported = -32006,

	UserRejectedRequest = 4001,
	Unauthorized = 4100,
	UnsupportedMethod = 4200,
	Disconnected = 4900,
	ChainDisconnected = 4901,
	NETWORK_ERROR = 'NETWORK_ERROR',
}

const ErrorMessages: { [code: number | string]: string } = {
	[ErrorCodes.ParseError]: 'An error occurred while parsing the request.',
	[ErrorCodes.InvalidRequest]:
		'Looks like request is invalid. Please try again',
	[ErrorCodes.MethodNotFound]: 'The requested method is not available.',
	[ErrorCodes.InvalidParams]:
		'The parameters provided are incorrect. Please review and try again.',
	[ErrorCodes.InternalError]:
		'Something went wrong on our end. Please try again later or contact support if the issue persists.',
	[ErrorCodes.ServerError]:
		'The chain seems to be overloaded. Please try after some time.',

	[ErrorCodes.UserRejectedRequest]:
		'Transaction rejected. Please approve the transaction in the wallet.',
	[ErrorCodes.Unauthorized]: 'You are not authorized to perform this action.',
	[ErrorCodes.UnsupportedMethod]:
		'This method is not supported by your wallet.',
	[ErrorCodes.Disconnected]:
		'Looks like the wallet is not connected correctly. Please disconnect and connect the wallet again.',
	[ErrorCodes.ChainDisconnected]:
		'Looks like blockchain network not connected correctly',

	[ErrorCodes.InvalidInput]: 'The input provided is not valid.',
	[ErrorCodes.ResourceNotFound]: 'The requested resource could not be found.',
	[ErrorCodes.ResourceUnavailable]:
		'The resource is currently unavailable. Please try again later.',
	[ErrorCodes.TransactionRejected]: 'The transaction was rejected.',
	[ErrorCodes.MethodNotSupported]: 'This method is not supported.',
	[ErrorCodes.LimitExceeded]: 'You have exceeded the allowed limit.',
	[ErrorCodes.JsonRpcVersionNotSupported]: 'JSON-RPC version not supported',
	[ErrorCodes.NETWORK_ERROR]:
		'It appears that your wallet is connected to an incorrect network. Please disconnect and connect the wallet again.',
};

const getErrorMessage = (code: number, error: any) => {
	if (code >= -32099 && code <= -32000 && !ErrorMessages[code]) {
		notify({
			code,
			message:
				'Please allow the transaction, and check you have enough gas and token.',
			error,
		});
		return 'Please allow the transaction, and check you have enough gas and token.';
	}

	notify({
		code,
		message:
			ErrorMessages[code] ||
			'Something is not right. Please refresh the page and reconnect wallet. If it does not work please wait, the network may be busy.',
		error,
	});
	return (
		ErrorMessages[code] ||
		'Something is not right. Please refresh the page and reconnect wallet. If it does not work please wait, the network may be busy.'
	);
};

export { ErrorCodes, ErrorMessages, getErrorMessage };
