import React from 'react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { Box, Typography } from '@mui/material';

const Container = styled('div')`
	width: 100%;
	padding: 10px;
	background: #e6e6e966;
	border-radius: 4px;
	border: 2px solid #000;
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const CoinContainer = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background: #ebeffc;
	border-radius: 16px;
	padding: 6px 10px;
`;

export const AmountInputBox: React.ComponentType<{
	text: string;
	leftTxt?: string;
	rightTxt?: string;
	onChange?(text: string): void;
}> = ({ text, onChange, leftTxt, rightTxt }) => {
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		// Regular expression to allow only numbers with or without decimals
		const regex = /^[0-9]*\.?[0-9]*$/;
		if (regex.test(value)) {
			onChange?.(value);
		}
	};

	return (
		<Container>
			<Row>
				<TextField
					variant="outlined"
					placeholder={'11,000'}
					sx={{
						'& .MuiOutlinedInput-root': {
							'& fieldset': {
								border: 'none',
							},
						},
					}}
					inputProps={{
						inputMode: 'numeric',
						pattern: '[0-9]*',
					}}
					value={text}
					onChange={handleInputChange}
				/>
				<CoinContainer>
					<img
						src={'/images/crypto/usdc.png'}
						height={25}
						width={25}
						alt={'USDC'}
					/>
					<Box width={10} />
					<Typography variant={'body1'} color={'#000'}>
						USDC
					</Typography>
				</CoinContainer>
			</Row>
			<Row>
				<Typography
					variant={'body1'}
					color={'#666677'}
					style={{ marginLeft: 14 }}
				>
					{leftTxt || `$11,000`}
				</Typography>
				<Typography
					variant={'caption'}
					color={'#666677'}
					style={{ marginLeft: 14 }}
				>
					In vault {rightTxt || `$15,000`}
				</Typography>
			</Row>
		</Container>
	);
};
