import axios from 'axios';

export type ErrorProps = { code: number; message: string };

export const notify = async (value: {
	code: number;
	message: string;
	error: any;
}) => {
	try {
		await axios.post('https://apis.vcred.trade/api/report-on-slack', {
			message: `*Code:* ${value.code} \n*Wallet:* ${
				localStorage.getItem('wagmi.recentConnectorId') || ''
			} \n*Message*: ${value.message} \n*Error*: ${JSON.stringify(
				value.error,
			)}\n`,
		});
	} catch (e) {
		//
	}
};
